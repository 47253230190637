<template>
  <div class="box">
    <div class="l_nav">
      <ul>
        <li @click="goIndex"><a href="javascript:;">首页 </a></li>
        <li><span>></span></li>
        <li>{{ params.name }}</li>
      </ul>
    </div>
    <template v-if="isShowPic">
      <ul class="picBox">
        <li v-for="(item,index) in mapDepotList" :key="index" :class="(index+1)%4==0?'noRight':''">
          <el-image class="img" :src="echoImg(item.bannerUrl)" @click="goDetails(item.id)"></el-image>
          <div class="name">{{item.journalisTitle}}</div>
        </li>
      </ul>
    </template>
    <div class="content" v-else>
      <template v-if="query.navigationType == 1">
        <ul class="ul">
          <li v-for="(item, index) in dates" :key="index" @click="goDetails(item.id)">
            <span class="circular" :style="'background:' + color"></span>
            <span class="liText">{{ item.journalisTitle }}</span>
            <span class="liTime">{{ toDate(item.releaseTime, "yyyy-MM-dd") }}</span>
          </li>
        </ul>
        <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-size="query.size" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
      </template>
      <template v-else>
        <div class="title">{{ details.journalisTitle }}</div>
        <div class="title_m">
          <span>信息来源：{{ details.message }}</span>
          <span>发布日期：{{ toDate(details.createTime, "yyyy-MM-dd") }}</span>
        </div>
        <div class="ql-editor" v-html="text"></div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { selectNavigaionList, getTextarea ,selectMapDepot} from "@/api/template";
export default {
  name: "Transfer",
  computed: {
    ...mapState(["color"]),
  },
  data() {
    return {
      params: "",
      query: {
        current: 1,
        navigationId: "",
        navigationType: "",
        size: 15,
      },
      total: 0,
      dates: [],
      details: {},
      text: "",
      isShowPic:false,
      mapDepotList:[]
    };
  },
  created() {
    // this.params = JSON.parse(window.sessionStorage.getItem("navigationValue"));
    // console.log(this.params,'list')
    this.params = this.$route.params
    if(this.params.id == 'img'){
      this.getSelectMapDepot()
      this.isShowPic = true
    }else {
      this.init()
      this.isShowPic = false
    }
  },
  methods: {
    init() {
      this.query.navigationId = this.params.id;
      this.query.navigationType = this.params.type;
      selectNavigaionList(this.query).then((res) => {
        if (this.params.type == 1) {
          this.dates = res.data.records;
        } else {
          this.details = res.data;
          this.getTextareaFun(res.data.journalisContent);
        }
        this.total = res.data.total;
      }).catch(() => {});
    },
    //获取精彩图片
    getSelectMapDepot(){
      let self = this
      selectMapDepot().then(res =>{
        if(res.code == 200){
          this.mapDepotList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getTextareaFun(journalisContent) {
      let params = {
        journalisContent: journalisContent,
      };
      getTextarea(params).then((res) => {
        this.text = res.data.dataHtml;
      });
    },
    handleSizeChange(size) {
      this.query.size = size;
      this.init();
    },
    handleCurrentChange(current) {
      this.query.current = current;
      this.init();
    },
    goIndex() {
      this.$router.push({name:'template-ninth'})
    },
    goDetails(id) {
      let data = {
        name: this.params.name,
        id: id,
      };
      window.sessionStorage.setItem("detailsValue", JSON.stringify(data));
      this.$router.push("/ninth-details");
    },
  },
};
</script>
<style lang="scss" scoped>
.box{
  width: 1000px;
  margin: 0 auto;
}
.l_nav {
  width: 1000px;
  margin: 0 auto;
  height: 40px;
  color: #333;
  ul {
    width: 1000px;
    margin: 0 auto;
    li {
      float: left;
      line-height: 40px;
      span {
        padding: 0 10px;
      }
      a{
        color: #333;
      }
    }
  }
}
.picBox{
  width: 999px;
  min-height: 600px;
  margin: 0 auto 20px;
  li{
    float: left;
    width: 230px;
    height: 190px;
    padding: 5px;
    background: #fff;
    margin-right: 13px;
    margin-bottom: 10px;
    cursor: pointer;
    .img{
      width: 100%;
      height: 164px;
    }
    .name{
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
  }
  .noRight{
    margin-right: 0;
  }
}
.content {
  width: 1000px;
  min-height: 600px;
  margin: 0 auto 20px;
  background: #fff;

  .ul{
    padding: 0 20px;
    min-height: 510px;
    li {
      position: relative;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      color: #545454;
      font-size: 14px;
      padding-left: 20px;
      .circular {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #1e3c5a;
        border-radius: 5px;
        vertical-align: middle;
        margin-right: 10px;
        position: absolute;
        left: 5px;
        top: 23px;
      }
      .liText {
        display: inline-block;
        width: 800px;
        height: 30px;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .liTime {
        float: right;
      }
    }
  }

  .pagination {
    clear: both;
    border-top: 1px solid #d2d2d2;
    padding-top: 20px;
    margin-top: 20px;
    padding-left: 20px;
  }
  .title {
    color: #1e3c5a;
    font-size: 26px;
    text-align: center;
    line-height: 80px;
    font-weight: bold;
  }
  .title_m {
    text-align: center;
    border-bottom: 1px solid #d7d7d7;
    padding: 20px;
    font-size: 14px;
    span {
      margin-right: 30px;
    }
  }
  .ql-editor {
    padding: 20px;
    line-height: 30px;
  }
}
.el-pager .active {
  color: #1e3c5a !important;
}
</style>
